export default {
  area: '康乐街道数智管理平台',
  objectName: '',
  //url
  hostUrl: 'http://jlhyjd.anluoyun.cn',
  apiHttpsUrl: 'https://mjkljdapi.anluoyun.cn/',
  apiRequestUrl: 'https://mjkljdapi.anluoyun.cn/api',
  apiUploadUrl: 'https://mjkljdapi.anluoyun.cn/api/UploadFile/UploadImgOrFile',
  apiUploadFiles:
    'https://mjkljdapi.anluoyun.cn/api/UploadFile/UploadImgOrFile',
  apiUploadvideo:
    'https://mjkljdapi.anluoyun.cn/api/UploadFile/UploadQiniuVideo',
}
